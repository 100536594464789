<template>
  <div class="box">
    <img src="@/assets/images/logo.png" class="test" alt="">
    <p class="text-center text_info">Enter Your Number</p>
    <div class="input">
      <input type="text" :placeholder="`+ ${getCountryCode}`"  class="game_input" v-model="phone" @input="valueChanged" />
    </div>

    <div class="mt-4 butn">
      <button :class="{ game_btn: true, activeBtn: isChanged }" id="sub_btn" :disabled="btnDisabled"
        @click="generateScript()">{{ btnDisabled ? "Loading ..." : "Subscribe" }}</button>
    </div>

    <div class="desc mt-4">
      <p class="text-center text-white " style="font-size: 16px">
       {{getMsg}}     </p>
    </div>
  </div>
</template>

<script>
import AlertService from '@/services/errors'
import ApiService from '@/services/api'
import { v4 as uuidv4 } from "uuid"

export default {
  data () {
    return {
      phone: '+964',
      isChanged: false,
      btnDisabled: false
    }
  },
  methods: {
    valueChanged (Val) {
      this.isChanged = true
    },
    async generateScript () {
      this.btnDisabled = true
      var phoneNumber = this.getCountryCode + this.phone.replaceAll('+' + this.getCountryCode, '')
      if (phoneNumber.length < 7) {
        AlertService.errorMessage("Invalid Phone")
        this.btnDisabled = false
        return
      }
      var uuid = "Game-Expert-App-" + uuidv4()
      var timestamp = this.getTimeStamp()
      this.$store.commit('setPhoneNumber', phoneNumber)
      this.$store.commit('setTi', uuid)
      this.$store.commit('setTS', timestamp)
      if (this.getCountryCode === '964') {
        const response = await ApiService.generateScript(
          uuid,
          timestamp
        )
        if (response && response.status === 200) {
          var s = response.data.s
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.text = s
          document.head.appendChild(script)
          const ev = new Event('DCBProtectRun')
          document.dispatchEvent(ev)
          await this.sendSMS()
        } else {
          this.btnDisabled = false
          this.isLoading = false
        }
      } else {
        this.sendSMS()
      }
    },
    async sendSMS () {
      const phoneNumber = this.$store.state.phoneNumber
      const ti = this.$store.state.ti
      const response = await ApiService.login(phoneNumber, ti)
      this.isLoading = false
      if (this.getCountryCode === '964') {
        if (response && response.status === 200) {
          this.$router.push('/verify')
        } else {
          this.btnDisabled = false
          this.errorMessage('Error')
        }
      } else {
        if (response.data.data && (response.data.code === 0 || response.data.code === 123 || response.data.code === 22)) {
          this.$router.push(`/verify/${phoneNumber}/${response.data?.data?.referenceId}`)
        } else {
          this.errorMessage(response?.message ?? 'Error')
        }
      }
    },
    getTimeStamp () {
      return parseInt(new Date().getTime() / 1000)
    }
  },
  mounted () {
    this.$i18n.locale = localStorage.getItem("lang") ?? "en"
    this.phone = '+' + this.getCountryCode
  },
  computed: {
    getCountryCode: function () {
      return localStorage.getItem('countryCode')
    },
    getMsg: function () {
      return this.getCountryCode === '964' ? 'Welcome to HighFit! You will receive 5 points on a daily basis for the price of 300 IQD/day. To unsubscribe, send 0 to 3821 (Free of charge).'
        : 'Welcome to HighFit, your best destination for fitness videos, workout plans, and nutrition tips. For just AED 2.10/ day, you\'ll unlock expert content to help you crush your fitness goals. You can unsubscribe anytime by sending C HIF to 1111. Start your fitness journey with HighFit today!'
    }
  }
}
</script>

<style>

img{

  width: auto;
  height: auto;

}

</style>
